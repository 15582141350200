/* Update a URL
 * @param {String} The URL to start with.
 * @param {string} name of the param to update.
 * @param {string|Object} value to set. If this is an object, then we'll add "name[property]=value" to the URL for each property/value pair.
 * @return [String] The new URL, if the URL changed, otherwise nil
 */
export function updateUrl(url, name, value) {
  const oldUrl = new URL(url, window.location.href)
  const newUrl = new URL(url, window.location.href)

  if (value) {
    if (typeof value === 'object') {
      for (const [key, val] of Object.entries(value)) {
        const subName = `${name}[${key}]`
        if (val) {
          newUrl.searchParams.set(subName, val)
        } else {
          newUrl.searchParams.delete(subName)
        }
      }
    } else {
      newUrl.searchParams.set(name, value)
    }
  } else {
    newUrl.searchParams.delete(name)
  }

  oldUrl.searchParams.sort()
  newUrl.searchParams.sort()

  if (oldUrl.toString() != newUrl.toString()) {
    return newUrl.toString()
  } else {
    return null
  }
}
